import React, {createContext, useCallback, useContext, useState} from 'react';
import {isEqual, isFunction} from 'lodash';
import {initialFilter} from './DonationUIHelpers';

const DonationUIContext = createContext();

export function useDonationUIContext() {
    return useContext(DonationUIContext);
}

export const DonationUIConsumer = DonationUIContext.Consumer;

export function DonationUIProvider({children}) {

    const [queryParams, setQueryParamsBase] = useState(initialFilter);
    const [ids, setIds] = useState([]);
    const setQueryParams = useCallback((nextQueryParams) => {
        setQueryParamsBase((prevQueryParams) => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams);
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams;
            }

            return nextQueryParams;
        });
    }, []);

    const value = {
        queryParams,
        setQueryParamsBase,
        ids,
        setIds,
        setQueryParams,
    };

    return (
        <DonationUIContext.Provider value={value}>
            {children}
        </DonationUIContext.Provider>
    );
}
