import axios from 'axios';
import { DonorURL } from '../../../config/UrlEndPoint';

export const URL = `${DonorURL}`;

// Login
export function oAuthLogin(email, password, oauth_app_uuid) {
    return axios.post(`${URL}oauth/login`, { email, password, oauth_app_uuid: oauth_app_uuid });
}

// Hit Me
export function oAuthMe(token) {
    return axios.get(`${URL}oauth/donor-me`, { headers: {"Authorization" : `Bearer ${token}`} });
}

// Get OauthApp
export function getOAuthApp(oAuthId) {
    return axios.get(`${URL}oauth_app_donor/${oAuthId}`);
}