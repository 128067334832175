import React, {useState, useMemo} from 'react';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../_metronic/_partials/controls';

// import DonaturFilter from './donatur-filter/DonaturFilter';
import { DonationList } from './donation-table/DonationList';
import { useDonationUIContext } from './DonationUIContext';
import DonationFilter from "./donation-filter/DonationFilter"
import {Badge, Collapse} from "react-bootstrap";


export const DonationCard = () => {

    const donationUIContext = useDonationUIContext();
    const donationUIProps = useMemo(() => {
        return {
            queryParams: donationUIContext.queryParams,
        };
    }, [donationUIContext]);

    const [statusFilter, setStatusFilter] = useState(false);
    const [open, setOpen] = useState(false);

    const changeShow = () => {
        setOpen(!open);

        // Set Status Filter To False
        setStatusFilter(false);
        const filter = donationUIProps.queryParams.filter;
        for (const [key, value] of Object.entries(filter)) {

            if (value !== '' && key) {
                setStatusFilter(true);
            }
        }
    };

    return (
        <Card>
            <CardHeader title="Donation List">
                <CardHeaderToolbar>
                    {statusFilter && (
                        <Badge className="mr-5" pill variant="danger">Filter Aktif</Badge>
                    )}
                    <button
                        type="button"
                        className="btn btn-warning mr-2"
                        onClick={changeShow}
                        aria-controls="collapse-filter-donatur"
                        aria-expanded={open}
                    >Cari
                    </button>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <Collapse in={open}>
                    <div id="collapse-filter-donatur">
                        <DonationFilter/>
                    </div>
                </Collapse>
                {/*<DonaturFilter/>*/}
                <DonationList/>

            </CardBody>
        </Card>
    );
};

export default DonationCard;
