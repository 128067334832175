import React, { useEffect, useMemo } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../modules/Donation/_redux/donationAction';
import * as uiHelpers from '../DonationUIHelpers';
import { DateColumnFormatter } from './column-formatters/DateColumnsFormatter';

import {
    getHandlerTableChange,
    NoRecordsFoundMessage,
    PleaseWaitMessage,
} from '../../../../_metronic/_helpers';
import * as columnFormatters from './column-formatters';
import { Pagination } from '../../../../_metronic/_partials/controls';
import { useDonationUIContext } from '../DonationUIContext';
import moment from 'moment';

export const DonationList = (props) => {
    // Donation UI Context
    const donationUIContext = useDonationUIContext();
    const donationUIProps = useMemo(() => {
        return {
            queryParams: donationUIContext.queryParams,
            setQueryParams: donationUIContext.setQueryParams,
        };
    }, [donationUIContext]);

    // Getting curret state of branch list from store (Redux)
    const { currentState } = useSelector(
        (state) => ({ currentState: state.donations }),
        shallowEqual,
    );

    const { totalCount, entities, listLoading } = currentState;
    const dispatch = useDispatch();
    useEffect(() => {
        //Requset ke Donation Action
        dispatch(actions.fetchDonations(donationUIProps.queryParams));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [donationUIProps.queryParams, dispatch]);
    // rowDonationItem.program === null ? `${rowDonationItem.type}` : `${rowDonationItem.type}, ${(rowDonationItem.program !== null ? rowDonationItem.program.name : "-")}`
    // console.log(entities);
    // Table columns
    const columns = [
        {
            dataField: 'donation_hid',
            text: 'ID',
        },
        {
            dataField: 'trx_date',
            text: 'Tanggal Tansaksi',
            formatter: DateColumnFormatter,
        },
        {
            dataField: 'donation_items',
            text: 'Jenis Donasi',
            formatter: columnFormatters.TypeColumnFormatter,
            classes: 'text-left',
            headerClasses: 'text-left',
        },
        {
            dataField: 'total_items',
            text: 'Jumlah Item',
            classes: 'text-right',
            headerClasses: 'text-right',
        },
        // {
        //     dataField: 'status',
        //     text: 'Status Donasi',
        //     formatter: columnFormatters.StatusColumnFormatter,
        // },

        {
            dataField: 'user_name',
            text: 'Petugas Konter',
        },
        {
            dataField: 'row',
            text: 'Payment',
            formatter: columnFormatters.PaymentColumnFormatter,
        },
        {
            dataField: 'total_amount',
            text: 'Amount',
            formatter: columnFormatters.PriceTotal,
        },
    ];

    const columnChild = [
        {
            dataField: 'name',
            text: 'Nama',
            formatter: columnFormatters.NameFormatter
        },
        {
            dataField: 'type',
            text: 'Tipe',
        },
        {
            dataField: 'program',
            text: 'Program',
        },
        {
            dataField: 'amount',
            text: 'Total',
            formatter: columnFormatters.PriceAmount,
        },
    ]

    const expandRow = {
        renderer: row => (
            <BootstrapTable
                wrapperClasses="table-respontesive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                striped
                bootstrap4
                bordered
                size="sm"
                remote
                keyField='uuid'
                data={row.donation_items === null ? [] : row.donation_items}
                columns={columnChild}
            />
        ),
        // onlyOneExpanding: true,
        showExpandColumn: true
    };

    // Table pagination properties
    const paginationOptions = {
        custom: true,
        totalSize: totalCount,
        sizePerPageList: uiHelpers.sizePerPageList,
        sizePerPage: donationUIProps.queryParams.pageSize,
        page: donationUIProps.queryParams.pageNumber,
    };

    // console.log(entities);

    return (
        <>
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => {
                    return (
                        <Pagination
                            isLoading={listLoading}
                            paginationProps={paginationProps}
                        >
                            <BootstrapTable
                                wrapperClasses="table-responsive"
                                classes="table table-head-custom table-vertical-center overflow-hidden"
                                bootstrap4
                                bordered={false}
                                remote
                                keyField="uuid"
                                data={entities === null ? [] : entities}
                                columns={columns}
                                onTableChange={getHandlerTableChange(
                                    donationUIProps.setQueryParams,
                                )}
                                expandRow={expandRow}
                                {...paginationTableProps}
                            >
                                <PleaseWaitMessage entities={entities} />
                                <NoRecordsFoundMessage entities={entities} />
                            </BootstrapTable>
                        </Pagination>
                    );
                }}
            </PaginationProvider>
        </>
    );
};
