/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as donationActions from "../_redux/DownloadReportActions";
import {Dropdown} from "react-bootstrap";
import {DropdownCustomToggler, DropdownMenu1, DropdownMenu2} from "../../../../_metronic/_partials/dropdowns";
import { ProgressBar, Button, Spinner } from "react-bootstrap";
import moment from "moment";


export function CustomListWidget1({ className }) {

  const [isHover, setIsHover] = useState({
    div1: false,
    div2: false,
    div3: false,
    div4: false,
    div5: false,
  })


  const {loadingProgress, button1, button2, button3, button4, button5} = useSelector((state) => state.exportList, shallowEqual)

   const dispatch = useDispatch()

  const downloadReport = (value, buttonClicked) => {
    const download = dispatch(donationActions.downloadReport(value, buttonClicked))
    return download
  }

  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* Head */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">Download Report</h3>
          {/* <div className="card-toolbar">
            <Dropdown className="dropdown-inline" drop="down" alignRight>
                <Dropdown.Toggle
                  id="dropdown-toggle-top2"
                  variant="transparent"
                  className="btn btn-light btn-sm font-size-sm font-weight-bolder dropdown-toggle text-dark-75">
                  Create
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                  <DropdownMenu2 />
                </Dropdown.Menu>
              </Dropdown>
          </div> */}
        </div>
        {/* Body */}
        <div className="card-body pt-2">
          <div className={`d-flex align-items-center p-2 mb-10 ${isHover.div1 && "bg-light"}`}
                onMouseOver={() => setIsHover((prevState) => ({
                  ...prevState,
                  div1: true
                }) )}
                onMouseOut={() => setIsHover((prevState) => ({
                  ...prevState,
                  div1: false
                }) )}
          >
            <span className="bullet bullet-bar bg-success align-self-stretch"></span>

              <div className="flex-shrink-0 m-0 mx-4">

              </div>

            <div className="d-flex flex-column flex-grow-1">
              <a
                className="text-dark-75 text-hover-success font-weight-bold font-size-lg mb-1"
              >
                Laporan
              </a>
              <span className="text-muted font-weight-bold">
                {moment().format("YYYY")}
              </span>
            </div>
            {button1 && loadingProgress ? 
              <Button
                className="btn btn-success float-right"
                disabled
              >
                Downloading...
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              </Button>
               : 
              <Button
                className="btn btn-success float-right"
                onClick={() => {
                  downloadReport(moment().format("YYYY"), "button1")
                  }}
              >
                Download
              </Button>
            }
          </div>

          <div className={`d-flex align-items-center p-2 mb-10 ${isHover.div2 && "bg-light"}`}
                onMouseOver={() => setIsHover((prevState) => ({
                  ...prevState,
                  div2: true
                }) )}
                onMouseOut={() => setIsHover((prevState) => ({
                  ...prevState,
                  div2: false
                }) )}>
            <span className="bullet bullet-bar bg-primary align-self-stretch"></span>

              <div className="flex-shrink-0 m-0 mx-4">

              </div>

            <div className="d-flex flex-column flex-grow-1">
              <a
                className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1"
              >
                Laporan
              </a>
              <span className="text-muted font-weight-bold">
                {moment().subtract(1, "years").format("YYYY")}
              </span>
            </div>
            {button2 && loadingProgress ? 
              <Button
                className="btn btn-primary float-right"
                disabled
              >
                Downloading...
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              </Button>
               : 
              <Button
                className="btn btn-primary float-right"
                onClick={() => {
                  downloadReport(moment().subtract(1, "years").format("YYYY"), "button2")
                  }}
              >
                Download
              </Button>
            }
          </div>

          <div className={`d-flex align-items-center p-2 mb-10 ${isHover.div3 && "bg-light"}`}
                onMouseOver={() => setIsHover((prevState) => ({
                  ...prevState,
                  div3: true
                }) )}
                onMouseOut={() => setIsHover((prevState) => ({
                  ...prevState,
                  div3: false
                }) )}>
            <span className="bullet bullet-bar bg-warning align-self-stretch"></span>

              <div className="flex-shrink-0 m-0 mx-4">

              </div>

            <div className="d-flex flex-column flex-grow-1">
              <a
                className="text-dark-75 text-hover-warning font-weight-bold font-size-lg mb-1"
              >
                Laporan
              </a>
              <span className="text-muted font-weight-bold">
                {moment().subtract(2, "years").format("YYYY")}
              </span>
            </div>
            {button3 && loadingProgress ? 
              <Button
                className="btn btn-warning float-right"
                disabled
              >
                Downloading...
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              </Button>
               : 
              <Button
                className="btn btn-warning float-right"
                onClick={() => {
                  downloadReport(moment().subtract(2, "years").format("YYYY"), "button3")
                  }}
              >
                Download
              </Button>
            }
          </div>

          <div className={`d-flex align-items-center p-2 mb-10 ${isHover.div4 && "bg-light"}`}
                onMouseOver={() => setIsHover((prevState) => ({
                  ...prevState,
                  div4: true
                }) )}
                onMouseOut={() => setIsHover((prevState) => ({
                  ...prevState,
                  div4: false
                }) )}>
            <span className="bullet bullet-bar bg-info align-self-stretch"></span>

              <div className="flex-shrink-0 m-0 mx-4">

              </div>

            <div className="d-flex flex-column flex-grow-1">
              <a
                className="text-dark-75 text-hover-info font-weight-bold font-size-lg mb-1"
              >
                Laporan
              </a>
              <span className="text-muted font-weight-bold">
                {moment().subtract(3, "years").format("YYYY")}
              </span>
            </div>
            {button4 && loadingProgress ? 
              <Button
                className="btn btn-info float-right"
                disabled
              >
                Downloading...
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              </Button>
               : 
              <Button
                className="btn btn-info float-right"
                onClick={() => {
                  downloadReport(moment().subtract(3, "years").format("YYYY"), "button4")
                  }}
              >
                Download
              </Button>
            }
          </div>

          <div className={`d-flex align-items-center p-2 mb-10 ${isHover.div5 && "bg-light"}`}
                onMouseOver={() => setIsHover((prevState) => ({
                  ...prevState,
                  div5: true
                }) )}
                onMouseOut={() => setIsHover((prevState) => ({
                  ...prevState,
                  div5: false
                }) )}>
            <span className="bullet bullet-bar bg-danger align-self-stretch"></span>

              <div className="flex-shrink-0 m-0 mx-4">

              </div>

            <div className="d-flex flex-column flex-grow-1">
              <a
                className="text-dark-75 text-hover-danger font-weight-bold font-size-lg mb-1"
              >
                Laporan
              </a>
              <span className="text-muted font-weight-bold">
                {moment().subtract(4, "years").format("YYYY")}
              </span>
            </div>
            {button5 && loadingProgress ? 
              <Button
                className="btn btn-danger float-right"
                disabled
              >
                Downloading...
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              </Button>
               : 
              <Button
                className="btn btn-danger float-right"
                onClick={() => {
                  downloadReport(moment().subtract(4, "years").format("YYYY"), "button5")
                  }}
              >
                Download
              </Button>
            }
          </div>
        </div>
      </div>
    </>
  );
}

const ItemDropdown = ({item}) => {
  return (<>
  <Dropdown className="dropdown-inline" alignRight>
    <Dropdown.Toggle
      variant="transparent"
      id="dropdown-toggle-top"
      className="btn btn-hover-light-primary btn-sm btn-icon"
      as={DropdownCustomToggler}>
      <i className="ki ki-bold-more-hor" />
    </Dropdown.Toggle>
    <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
      <DropdownMenu1 />
    </Dropdown.Menu>
  </Dropdown>
  </>);
};