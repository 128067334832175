import axios from "axios";
import { DonorURL } from '../../../config/UrlEndPoint';


export const URL = `${DonorURL}`;


// items => filtered/sorted result for table data
export function sendOtp(queryParams) {
  return axios.post(`${URL}otp`, {email: queryParams});
}

// items => filtered/sorted result for table data
export function verifyOtp(params) {
  return axios.post(`${URL}verifyOtp`, {email: params.email, otp: params.otp});
}
