import React, { Suspense, lazy } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout';
import { DashboardPage } from './pages/DashboardPage';
// import { MyPage } from './pages/MyPage';
import { DonationPage } from './modules/Donation/DonationPage';
import { DownloadReportPage } from "./modules/DownloadReport/DownloadReportPage"

const UserProfilepage = lazy(() =>
    import('./modules/UserProfile/UserProfilePage'),
);

export default function BasePage() {

    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/dashboard"/>
                }

                {/* GLOBAL */}
                <ContentRoute path="/dashboard" component={DashboardPage}/>

                <ContentRoute exact path="/donasi" component={DonationPage}/>
                <ContentRoute exact path="/download-report" component={DownloadReportPage}/>
                <Route path="/user-profile" component={UserProfilepage} />

                <Redirect to="error/error-v1"/>
            </Switch>
        </Suspense>
    );
}
