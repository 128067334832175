import * as requestFromServer from "./activationCrud";
import { activationSlice, callTypes } from "./activationSlice";
import { toastOption } from "../../../../_metronic/_helpers";
import { toast } from "react-toastify";

const { actions } = activationSlice;

export const sendOtp = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .sendOtp(queryParams)
    .then((response) => {
      dispatch(actions.activationOtp({ response, queryParams }));
      return response
    })
    .catch((error) => {
      console.log(error);
      toast.error(error.response.data.message)
      if (error.response.status === 401)
        error = error;
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return error
    });
};

export const verifyOtp = (params) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .verifyOtp(params)
    .then((response) => {
      dispatch(actions.verifyOtp(response));
      return response
    })
    .catch((error) => {
      console.log(error);
      toast.error("Kode OTP tidak cocok!")
      if (error.response.status === 401)
        error = error;
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return error
    });
};

// export const createActivation = (activationForCreation) => (dispatch) => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .createActivation(activationForCreation)
//     .then((response) => {
//       dispatch(actions.activationReload);
//       return response.data;
//     })
//     .catch((error) => {
//       error = error.response.data.message;
//       dispatch(actions.catchError({ error, callType: callTypes.list }));
//     });
// };

// export const updateActivation = (activation) => (dispatch) => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .updateActivation(activation)
//     .then((response) => {
//       dispatch(actions.activationReload);
//       return response.data;
//     })
//     .catch((error) => {
//       error = error.response.data.message;
//       dispatch(actions.catchError({ error, callType: callTypes.list }));
//     });
// };

// export const deleteActivation = (id) => (dispatch) => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .deleteActivation(id)
//     .then((response) => {
//       dispatch(actions.activationReload);
//       return response.data;
//     })
//     .catch((error) => {
//       error = error.response.data.message;
//       dispatch(actions.catchError({ error, callType: callTypes.list }));
//     });
// };
