export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "50", value: 50 },
];

export const initialFilter = {
  filter: {
    trxDate: "",
    id_donation: "",
    donation_type: "",
    user_name: "",
    email: "",
    payment: "",
    amountgte: "",
    amountlte: "",
    start_date: "",
    end_date: "",
  },
  pageNumber: 1,
  pageSize: 10,
};

export const badge = (status) => {
  let data = {};
  switch (status) {
    case "VOID":
      data = { type: "danger" };
      break;
    case "REQUEST_VOID":
      data = { type: "warning" };
      break;
    case "SETTLE":
      data = { type: "success" };
      break;
    default:
      data = { type: "info" };
  }

  return data;
};

export const statusDonasi = [
  { value: "SETTLE", name: "SETTLE" },
  { value: "COLLECT", name: "COLLECT" },
  { value: "DRAFT", name: "DRAFT" },
  { value: "RECOG", name: "RECOG" },
  { value: "VOID", name: "VOID" },
  { value: "REQUEST_VOID", name: "REQUEST VOID" },
];

export const paymentMethod = [
  { value: "Bank", name: "BANK" },
  { value: "Cash", name: "CASH" },
  { value: "Bank + Cash", name: "BANK + CASH" },
  { value: "Bank + EChannel", name: "BANK / ECHANNEL" },
];
