import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';

import * as auth from '../app/modules/Auth/_redux/authRedux';

import { exportListSlice } from '../app/modules/DownloadReport/_redux/DownloadReportSlice';
import { donationSlice } from '../app/modules/Donation/_redux/donationSlice';
import { loginSessionSlice } from '../app/modules/UserProfile/LoginSession/redux/loginSessionSlice';
import { activationSlice } from '../app/modules/Activation/redux/activationSlice';

export const rootReducer = combineReducers({
    auth: auth.reducer,
    exportList: exportListSlice.reducer,
    donations: donationSlice.reducer,
    loginSession: loginSessionSlice.reducer,
    activation: activationSlice.reducer
});

export function* rootSaga() {
    yield all([auth.saga()]);
}
