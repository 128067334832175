/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import VerifyCard from "./modules/Verify/VerifyCard";
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout';
import { OAuthAppPage } from "./modules/OAuthApp/OAuthAppPage";
import { ActivationPage } from "./modules/Activation/ActivationPage";



export function Routes() {
    const { isAuthorized } = useSelector(
        ({ auth }) => ({
            isAuthorized: auth.user != null,
        }),
        shallowEqual
    );

    return (
        <Switch>
            {/* <Route path="/verify/donation/:id/">
                {({ history, match }) => (
                    // <DashboardPage></DashboardPage>
                    // <Layout>

                    <div className="mt-300">
                        <VerifyCard />
                    </div>
                    // <AuthPage />

                    // </Layout>

                    // <div>
                    //     {console.log(match.params.id)}

                    // </div>
                    // <BasePage />
                )}
            </Route> */}
            <ContentRoute exact path="/verify/donation/:id" component={VerifyCard} />
            <ContentRoute exact path="/activation" component={ActivationPage} />
            <ContentRoute exact path="/oauth/login/:id" component={AuthPage} />
            <ContentRoute exact path="/oauth/confirmation/:id/:token" component={OAuthAppPage} />
            {!isAuthorized ? (
                /*Render auth page when user at `/auth` and not authorized.*/
                <Route>
                    <AuthPage />
                </Route>
            ) : (
                /*Otherwise redirect to root page (`/`)*/
                <Redirect from="/auth" to="/" />
            )}

            <Route path="/error" component={ErrorsPage} />
            <Route path="/logout" component={Logout} />



            {!isAuthorized ? (
                /*Redirect to `/auth` when user is not authorized*/
                <Redirect to="/auth/login" />
            ) : (
                <Layout>
                    <BasePage />
                </Layout>
            )}
        </Switch>
    );
}
