import React from 'react';
import { currencyFormatter } from '../../../../../_metronic/_helpers/UtilityHelpers';

export function PaymentColumnFormatter(cellContent, row) {
    // console.log(row.payment_cash, row.payment_edc);

    if (row.payment_cash > 0 && row.payment_edc == 0) {
        return (
            <span>
                <strong style={{ color: 'green' }}>Cash</strong>
            </span>
        )
    } else if (row.payment_cash > 0 && row.payment_edc > 0) {
        return (
            <span>
                <strong style={{ color: 'red' }}>Bank + Cash</strong>
            </span>
        )

    } else if (row.payment_cash == 0 && row.payment_edc > 0) {
        return (
            <span>
                <strong style={{ color: 'blue' }}>Bank</strong>
            </span>
        );
    } else if (row.trx_hash != null || (row.payment_cash == null && row.payment_edc == null)) {
        return (
            <span>
                <strong style={{ color: 'purple' }}>BANK / ECHANNEL</strong>
            </span>
        );
    }

    // if (row.payment_edc_provider !== null && parseInt(row.payment_cash) !== 0) {
    //     return (
    //         <span>
    //             <strong style={{color: 'red'}}>Bank + Cash</strong>
    //         </span>
    //     );
    // }

    // if (row.payment_edc_provider !== null && parseInt(row.payment_cash) === 0) {
    //     return (
    //         <span>
    //             <strong style={{color: 'blue'}}>Bank</strong>
    //         </span>
    //     );
    // }

    // return (
    //     <span>
    //         <strong style={{color: 'green'}}>Cash</strong>
    //     </span>
    // );
}

export function PriceTotal(cellContent, row) {
    // let amount = 0
    // for (let donationItemElement of row.donation_items) {
    //     amount += Number(donationItemElement.amount)
    // }
    // console.log(cellContent);
    return (
        <span>
            {currencyFormatter(parseInt(cellContent))}
        </span>
    );
}

export function PriceAmount(cellContent, row) {
    return (
        <span>
            {currencyFormatter(parseInt(row.amount))}
        </span>
    );
}

export function TrxItemFormatter(cell, row) {
    return (
        <span>{cell.length}</span>
    );
}

export function NameFormatter(cellContent, row) {
    let name = [];
    for (let i = 1; i <= 7; i++) {
        let x = `name_${i}`;
        if (row[x]) {
            name.push(row[x]);
        }
    }
    return (
        <span>
            {name.join(', ')}
        </span>
    );
}


