import { createSlice } from '@reduxjs/toolkit';

const initialExportListState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    exportListForEdit: undefined,
    lastError: null,
    loadingProgress: false,
    button1: false,
    button2: false,
    button3: false,
    button4: false,
    button5: false,
};

export const callTypes = {
    list: 'list',
    action: 'action',
};

export const exportListSlice = createSlice({
    name: 'exportList',
    initialState: initialExportListState,
    reducers: {
        catchError: (state, action) => {
            state.error = action.payload.error;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
                state.loadingProgress = false
                state.button1 = false
                state.button2 = false
                state.button3 = false
                state.button4 = false
                state.button5 = false
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                console.log('masuk startcall');
                console.log(action.payload);

                state.actionsLoading = true;
                state.loadingProgress = true;
                switch (action.payload.buttonClicked) {
                    case "button1":
                        state.button1 = true
                        break;
                    case "button2":
                        state.button2 = true
                        break;
                    case "button3":
                        state.button3 = true
                        break;
                    case "button4":
                        state.button4 = true
                        break;
                    case "button5":
                        state.button5 = true 
                        break;
                
                    default:
                        break;
                }
            

            }
        },

        // findExportLists
        exportListsFetched: (state, action) => {
            // const { total_items, items, exportList_item_array } = action.payload;
            const { total_items, items } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = items;
            state.totalCount = total_items;
            state.button1 = false
            state.button2 = false
            state.button3 = false
            state.button4 = false
            state.button5 = false
            // state.exportList_item_array = exportList_item_array
        },

        // Set to default state actionLoading & error
        exportListReload: (state, action) => {
            state.actionsLoading = false;
            state.listLoading = false;
            state.error = null;
            state.loadingProgress = false;
            state.button1 = false
            state.button2 = false
            state.button3 = false
            state.button4 = false
            state.button5 = false
        },

    },
});