/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import * as actions from "./redux/changePasswordAction"
import { useDispatch } from "react-redux";


export function ChangePasswordPage({ user, history }) {

  const dispatch = useDispatch()

  const validationSchema = Yup.object().shape({
    password: Yup.string().required('Password is required'),
    confirmationPassword: Yup.string()
     .oneOf([Yup.ref('password'), null], 'Passwords must match')
  })

  const changePassword = (value) => {
    Object.assign(value, { email: user })
    dispatch(actions.updatePassword(value)).then(res => {
     if (res.status === 200) history.push("/") 
    })
  }

  return (
    <>
      <Formik
        className="form fv-plugins-bootstrap fv-plugins-framework"
        enableReinitialize={true}
        initialValues={{}}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          changePassword(values)
        }}
      >
        {({ handleSubmit, setFieldValue }) => (
          <>
            <div className="form-group fv-plugins-icon-container">
              <Field
                placeholder="Password baru"
                type="password"
                className={`form-control form-control-solid h-auto py-5 px-6`}
                name="password"
              />
              <ErrorMessage
                name="password"
                render={(msg) => (
                  <div style={{ color: "red" }}>{msg}</div>
                )}
              />
            </div>
            <div className="form-group fv-plugins-icon-container">
              <Field
                placeholder="Konfirmasi password"
                type="password"
                className={`form-control form-control-solid h-auto py-5 px-6`}
                name="confirmationPassword"
              />
              <ErrorMessage
                name="confirmationPassword"
                render={(msg) => (
                  <div style={{ color: "red" }}>{msg}</div>
                )}
              />
            </div>
            <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                <div className="my-3 mr-2"></div>
                <button
                  id="kt_login_signin_submit"
                  type="submit"
                  onClick={() => {
                    handleSubmit()
                  }}
                  className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                >
                    <span>Submit</span>
                </button>
            </div>
          </>
        )}
      </Formik>
    </>
  );
}
