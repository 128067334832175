import React from 'react';
import {DonationCard} from './DonationCard';
import {DonationUIProvider} from './DonationUIContext';

export function DonationPage({history}) {
    return (
        <DonationUIProvider>
            <DonationCard/>
        </DonationUIProvider>

    );
}
