/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { Dropdown } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useHtmlClassService } from "../../../../_metronic/layout";
import { DropdownMenu2 } from "../../../../_metronic/_partials/dropdowns";
import * as donationCrud from "../DashboardCrud";
import { currencyFormatter } from "../ColumnFormatter/ColumnFormatter";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toastOption } from "../../../../_metronic/_helpers/ToastHelpers";
import { toast } from "react-toastify";
import { actions as auth } from "../../../modules/Auth/_redux/authRedux";

export function CustomWidget1({ className }) {
  const uiService = useHtmlClassService();
  const dispatch = useDispatch();

  const [findDonation, setFindDonation] = useState();
  const fetchDonation = async () => {
    try {
      const findDonation = await donationCrud.findDonation();
      // console.log(findDonation);
      setFindDonation(findDonation);
    } catch (error) {
      // console.log(error);
      if (error.response.data.error === 401) {
        toast.error(error.response.data.message, toastOption);
        dispatch(auth.logout());
      }
    }
  };
  useEffect(() => {
    fetchDonation();
  }, []);

  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseDanger: objectPath.get(
        uiService.config,
        "js.colors.theme.base.danger"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService]);

  return (
    <div className={`${className}`}>
      {/* Body */}
      <div className="row">
        <div className="col-lg-3">
          <div className="bg-light-danger px-6 py-8 rounded-xl mr-7 mb-7">
            <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
              ></SVG>
            </span>
            <a className="text-danger font-weight-bold font-size-h6 mt-2">
              Total Donasi: <br /> <br />
              {findDonation &&
                findDonation.data &&
                currencyFormatter(findDonation.data.total_donation)}
            </a>
          </div>
        </div>
        {findDonation &&
          findDonation.data.donasi_fund_type.map((item, index) => {
            {
              return (
                index < 3 && (
                  <div className="col-lg-3 col-md-4">
                    <div
                      className={`${
                        index % 2 === 0
                          ? "bg-light-primary"
                          : "bg-light-success"
                      } px-6 py-8 rounded-xl mr-7 mb-7`}
                    >
                      <span
                        className={`svg-icon svg-icon-3x ${
                          index % 2 === 0
                            ? "svg-icon-primary"
                            : "svg-icon-success"
                        } d-block my-2`}
                      >
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Media/Equalizer.svg"
                          )}
                        ></SVG>
                      </span>
                      <a
                        className={`${
                          index % 2 === 0 ? "text-primary" : "text-success"
                        } font-weight-bold font-size-h6`}
                      >
                        {item.type} <br />
                        Total Donasi :{" "}
                        {item.total_item_donation != 0
                          ? `${item.total_item_donation} Kali`
                          : item.total_item_donation}{" "}
                        <br />
                        {currencyFormatter(item.total_donation)}
                      </a>
                    </div>
                  </div>
                )
              );
            }
          })}
      </div>
      <div className="row mt-7">
        {findDonation &&
          findDonation.data.donasi_fund_type.map((item, index) => {
            {
              return (
                index >= 3 && (
                  <div className="col-lg-3 col-md-4">
                    <div
                      className={`${
                        index % 2 === 0
                          ? "bg-light-primary"
                          : "bg-light-success"
                      } px-6 py-8 rounded-xl mr-7 mb-7`}
                    >
                      <span
                        className={`svg-icon svg-icon-3x ${
                          index % 2 === 0
                            ? "svg-icon-primary"
                            : "svg-icon-success"
                        } d-block my-2`}
                      >
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Media/Equalizer.svg"
                          )}
                        ></SVG>
                      </span>
                      <a
                        className={`${
                          index % 2 === 0 ? "text-primary" : "text-success"
                        } font-weight-bold font-size-h6`}
                      >
                        {item.type} <br />
                        Total Donasi :{" "}
                        {item.total_item_donation != 0
                          ? `${item.total_item_donation} Kali`
                          : item.total_item_donation}{" "}
                        <br />
                        {currencyFormatter(item.total_donation)}
                      </a>
                    </div>
                  </div>
                )
              );
            }
          })}
      </div>
    </div>
  );
}
