import * as requestFromServer from "./changePasswordCrud";
import { changePasswordSlice, callTypes } from "./changePasswordSlice";
import { toastOption } from "../../../../_metronic/_helpers";
import { toast } from "react-toastify";

const { actions } = changePasswordSlice;

export const updatePassword = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .changePassword(queryParams)
    .then((response) => {
      toast.success("Berhasil mengganti password!", toastOption)
      dispatch(actions.changePasswordOtp(response));
      return response
    })
    .catch((error) => {
      console.log(error);
      toast.error("Gagal mengganti password!", toastOption)
      if (error.response.status === 401) 
      error = error;
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return error
    });
};

export const verifyOtp = (params) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .verifyOtp(params)
    .then((response) => {
      dispatch(actions.verifyOtp(response));
      return response
    })
    .catch((error) => {
      console.log(error.status);
      if (error.response.status === 401)
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return error
    });
};

// export const createChangePassword = (changePasswordForCreation) => (dispatch) => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .createChangePassword(changePasswordForCreation)
//     .then((response) => {
//       dispatch(actions.changePasswordReload);
//       return response.data;
//     })
//     .catch((error) => {
//       error = error.response.data.message;
//       dispatch(actions.catchError({ error, callType: callTypes.list }));
//     });
// };

// export const updateChangePassword = (changePassword) => (dispatch) => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .updateChangePassword(changePassword)
//     .then((response) => {
//       dispatch(actions.changePasswordReload);
//       return response.data;
//     })
//     .catch((error) => {
//       error = error.response.data.message;
//       dispatch(actions.catchError({ error, callType: callTypes.list }));
//     });
// };

// export const deleteChangePassword = (id) => (dispatch) => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .deleteChangePassword(id)
//     .then((response) => {
//       dispatch(actions.changePasswordReload);
//       return response.data;
//     })
//     .catch((error) => {
//       error = error.response.data.message;
//       dispatch(actions.catchError({ error, callType: callTypes.list }));
//     });
// };
