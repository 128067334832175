import React, { useEffect, useMemo, useState } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useDonationUIContext } from "../DonationUIContext";
import {
  statusDonasi,
  initialFilter,
  paymentMethod,
} from "../DonationUIHelpers";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import { getFundType } from "../../MasterData/_redux/fund-type/fundTypeCrud";
import { letterChoice } from "../DonationUIHelpers";
import moment from "moment";

const prepareFilter = (queryParams, values) => {
  const {
    donation_id,
    donation_type,
    user_name,
    email,
    payment,
    start_date,
    end_date,
    amountgte,
    amountlte,
  } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};

  //add to Object filter
  filter.donation_id = donation_id;
  filter.donation_type = donation_type;
  filter.user_name = user_name;
  filter.email = email;
  filter.start_date = start_date ?? "";
  filter.end_date = end_date ?? "";
  filter.payment = payment;
  filter.amountgte = amountgte;
  filter.amountlte = amountlte;

  newQueryParams.filter = filter;
  return newQueryParams;
};

const DonationFilter = ({ listLoading }) => {
  // Branchs UI Context
  const donationUIContext = useDonationUIContext();
  const donationUIProps = useMemo(() => {
    return {
      setQueryParams: donationUIContext.setQueryParams,
      queryParams: donationUIContext.queryParams,
    };
  }, [donationUIContext]);

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(donationUIProps.queryParams, values);
    if (!isEqual(newQueryParams, donationUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      donationUIProps.setQueryParams(newQueryParams);
    }
  };

  const [branchList, setBranchList] = useState([]);
  const [fundType, setFundType] = useState([]);
  //UNTUK DATERANGE
  const [onFocused, setOnFocused] = useState(null);
  const [tanggal, setTanggal] = useState({
    start_date: null,
    end_date: null,
  });
  // const fetchBranch = async (value) => {
  //     const result = await getBranchAll()
  //     setBranchList(result.data.data)
  // }

  const fetchFundType = async () => {
    const result = await getFundType()
    // console.log(result);
    setFundType(result.data.data)
  }

  useEffect(() => {
    // fetchBranch()
    fetchFundType()
  }, [])

  return (
    <Formik
      initialValues={initialFilter.filter}
      onSubmit={(values) => {
        applyFilter(values);
        // console.log(values)
      }}
    >
      {({ values, handleSubmit, handleBlur, setFieldValue, handleChange }) => (
        <>
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
              <div className="col-lg-4">
                <DateRangePicker
                  small={true}
                  startDate={tanggal.start_date}
                  endDate={tanggal.end_date}
                  onDatesChange={({ startDate, endDate }) => {
                    setTanggal({
                      start_date: startDate,
                      end_date: endDate,
                    });
                    setFieldValue("start_date", startDate);
                    setFieldValue("end_date", endDate);
                  }}
                  focusedInput={onFocused}
                  onFocusChange={(focusedInput) => setOnFocused(focusedInput)}
                  numberOfMonths={1}
                  isOutsideRange={() => false}
                  showClearDates={true}
                  startDateId="start_date"
                  endDateId="end_date"
                  hideKeyboardShortcutsPanel={true}
                />
                <small className="form-text text-muted">
                  <b>Cari</b> berdasarkan tanggal
                </small>
              </div>
              <div className="col-lg-4">
                <input
                  type="text"
                  className="form-control"
                  name="donation_id"
                  placeholder="Contoh: 322111"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("donation_id", e.target.value);
                  }}
                />
                <small className="form-text text-muted">
                  <b>Cari</b> ID Donation
                </small>
              </div>
              <div className="col-lg-4">
                <input
                  type="text"
                  className="form-control"
                  name="user_name"
                  placeholder="Input petugas konter"
                  onBlur={handleBlur}
                  value={values.city}
                  onChange={(e) => {
                    setFieldValue("user_name", e.target.value);
                  }}
                />
                <small className="form-text text-muted">
                  Cari Berdasarkan <b>Petugas Konter</b>
                </small>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-4">
                <input
                  type="number"
                  className="form-control"
                  name="amountgte"
                  placeholder="Cari berdasarkan Jumlah Lebih dari"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("amountgte", e.target.value);
                  }}
                />
                <small className="form-text text-muted">
                  <b>Cari Berdasarkan</b> Jumlah Lebih Dari
                </small>
              </div>
              <div className="col-lg-4">
                <input
                  type="number"
                  className="form-control"
                  name="amountlte"
                  placeholder="Cari berdasarkan Jumlah Kurang dari"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("amountlte", e.target.value);
                  }}
                />
                <small className="form-text text-muted">
                  <b>Cari Berdasarkan</b> Jumlah Kurang Dari
                </small>
              </div>
              <div className="col-lg-4">
                <select
                  className="form-control"
                  placeholder="Cari Type"
                  name="donation_type"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("donation_type", e.target.value);
                  }}
                >
                  <option value="">ALL</option>
                  {fundType.map((val) => (
                    <option key={val.uuid} value={val.name}>
                      {val.name}
                    </option>
                  ))}
                </select>
                <small className="form-text text-muted">
                  Cari Berdasarkan <b>Tipe Donasi</b>
                </small>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-4">
                <select
                  className="form-control"
                  placeholder="Cari Type"
                  name="payment"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("payment", e.target.value);
                  }}
                >
                  <option value="">ALL</option>
                  {paymentMethod.map((val) => (
                    <option key={val.value} value={val.value}>
                      {val.name}
                    </option>
                  ))}
                </select>
                <small className="form-text text-muted">
                  Cari Berdasarkan <b>Payment</b>
                </small>
              </div>
            </div>
          </form>
          <button
            onClick={() => handleSubmit()}
            className="btn btn-primary btn-elevate float-right mb-20"
          >
            Cari
          </button>
          <hr className="mt-25" />
        </>
      )}
    </Formik>
  );
};
export default DonationFilter;
