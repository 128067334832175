import axios from 'axios';
import { DonorURL } from '../../../config/UrlEndPoint';

// export const LOGIN_URL = `${DonorURL}signin`;
// export const ME_URL = `${DonorURL}me`;

export const URL = `${DonorURL}`;

export function login(email, password) {
    return axios.post(`${URL}signin`, { email, password });
}

export function getUserByToken() {
    // Authorization head should be fulfilled in interceptor.
    return axios.get(`${URL}me`);
}

export function updatePass(data) {
    return axios.put(`${URL}profile/changepass`, data);
}


