import React, { useState, useMemo } from 'react';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../_metronic/_partials/controls';
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { ContentRoute } from "../../../_metronic/layout";
import { Link, Switch, Redirect } from "react-router-dom";
import { Formik, Field, Form } from 'formik';
import { Input, Select } from '../../../_metronic/_partials/controls';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import SVG from 'react-inlinesvg';
import { Table } from 'react-bootstrap';
import { verifyDonation } from './action/verifyAction';


// import Login from "./Login";
import "../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";




export const VerifyCard = ({ match }) => {

    const [donasi, setDonasi] = useState({
        data: null,
        message: null,
        verifyStatus: false
    })

    const [tipeDonasi, setTipeDonasi] = useState("")
    const [nama, setNama] = useState("")

    const [show, setShow] = useState(false)

    const verifyDonationPost = (amount) => {
        verifyDonation({ id: match.params.id, amount }).then((result) => {
            setShow(true)
            // console.log(result.data);
            setDonasi(result.data)

            let tipeDonasi = ""
            result.data.data.donation_items.forEach((item, index) => {
                if (index === 0) {
                    tipeDonasi += `${item.type}`
                } else {
                    tipeDonasi += `, ${item.type}`
                }
            })

            let namaDonatur = ""
            const donorName = result.data.data.donor.name
            // console.log(result);

            // let splitName = donorName.split(" ")
            // for (const index in splitName) {
            //     let item = splitName[index]
            //     let length = item.length
            //     if (index == 0) {
            //         if (length > 2) {
            //             namaDonatur += item.replace(2, length, "*")
            //         } else {
            //             namaDonatur += item
            //         }
            //     }

            // }
            var regex = /\b(\w{2})\w+(\w)\b/g;
            setNama(donorName.replace(regex, '$1**$2'))
            // setNama(namaDonatur)
            setTipeDonasi(tipeDonasi)
        }).catch((error) => {
            setShow(true)
        })
    }


    return (
        <>
            <div className="d-flex flex-column flex-root">
                {/*begin::Login*/}
                <div
                    className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
                    id="kt_login"
                >
                    {/*begin::Aside*/}
                    <div
                        className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
                        style={{
                            backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-4.jpg")})`,
                        }}
                    >
                        {/*begin: Aside Container*/}
                        <div className="d-flex flex-row-fluid flex-column justify-content-between text-center text-lg-left">
                            {/* start:: Aside header */}
                            <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
                                <img
                                    alt="Logo"
                                    className="max-h-70px"
                                    src={toAbsoluteUrl("/media/logos/logo-letter-1.png")}
                                />
                            </Link>
                            {/* end:: Aside header */}

                            {/* start:: Aside content */}
                            <div className="flex-column-fluid d-flex flex-column justify-content-center">
                                <h3 className="font-size-h1 mb-5 text-white">
                                    Portal Verifikasi
                                </h3>
                                {/* <p className="font-weight-lighter text-white opacity-80">
                  <i>Pelayanan Mandiri Dompetdhuafa</i>
                </p> */}
                                <p className="font-weight-lighter text-white opacity-80">
                                    <i>Verifikasi Donasi Mandiri Dompet Dhuafa</i>
                                </p>
                            </div>
                            {/* end:: Aside content */}

                            {/* start:: Aside footer for desktop */}
                            <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                                <div className="opacity-70 font-weight-bold	text-white">
                                    &copy; 2021 Dompet Dhuafa
                                </div>

                            </div>
                            {/* end:: Aside footer for desktop */}
                        </div>
                        {/*end: Aside Container*/}
                    </div>
                    {/*begin::Aside*/}

                    {/*begin::Content*/}
                    <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">

                        {/* begin::Content body */}
                        <div className="flex-column-fluid flex-top mt-5 mt-lg-0">
                            {!show && <Card>
                                <CardHeader title="Verifikasi Donasi">

                                </CardHeader>
                                <CardBody>
                                    <div className="text-center mb-10">
                                        <h3>Input Jumlah Donasi Untuk Verifikasi Donasi</h3>
                                    </div>
                                    <Formik
                                        // enableReinitialize={true}
                                        initialValues={{ amount: "" }}
                                        // validationSchema={DonaturEditSchema}
                                        onSubmit={(values) => {
                                            // console.log(values);
                                            verifyDonationPost(values.amount)
                                            // if (values.type === '') {
                                            //     values.type = 'INDIVIDU'
                                            // }
                                            // saveDonatur(values);
                                        }}>
                                        {({ handleSubmit, handleChange, setFieldValue, values, isSubmitting }) => (
                                            <>
                                                <Form className="form form-label-right">
                                                    <div className="form-group row">
                                                        <div className="col-md-3"></div>
                                                        <div className="col-md-6">
                                                            <Field
                                                                name="amount"
                                                                component={Input}
                                                                type="number"
                                                                placeholder="500000"
                                                                label="Jumlah Donasi"
                                                            />
                                                        </div>
                                                        <div className="col-md-3"></div>

                                                    </div>
                                                    <div className="form-group row">
                                                        <div className="col-md-3"></div>
                                                        <div className="col-md-6">
                                                            <button
                                                                onClick={() => handleSubmit()}
                                                                className="btn btn-primary btn-elevate text-center float-right mb-20"
                                                            >
                                                                Verifikasi Donasi
                                                            </button>
                                                        </div>
                                                        <div className="col-md-3"></div>

                                                    </div>
                                                </Form>
                                            </>
                                        )}

                                    </Formik>
                                </CardBody>


                            </Card>}
                            {
                                show && donasi.verifyStatus && <Card>
                                    <CardBody>
                                        <div className="row">

                                            <div className="col-md-3"></div>
                                            <div className="col-md-6 text-center">
                                                <h3 className="text-center">Bukti Setor Anda Asli</h3>
                                            </div>
                                            <div className="col-md-3"></div>
                                            <div className="col-md-3"></div>
                                            <div className="col-md-6 text-center">
                                                <span className="svg-icon svg-icon-primary svg-icon-10x ">
                                                    <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Check.svg')} />
                                                </span>
                                            </div>
                                            <div className="col-md-3"></div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-8">
                                                <Table>
                                                    <tr>
                                                        <td>ID Donasi</td>
                                                        <td>:</td>
                                                        <td className="text-right">{donasi.data.donation_hid}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>ID Donatur</td>
                                                        <td>:</td>
                                                        <td className="text-right">{donasi.data.donor.donor_id}</td>

                                                    </tr>
                                                    <tr>
                                                        <td>Nama Donatur</td>
                                                        <td>:</td>
                                                        <td className="text-right">{nama}</td>

                                                    </tr>
                                                    {/* <tr>
                                                        <td>Jenis Donasi</td>
                                                        <td>:</td>
                                                        <td className="text-right">{tipeDonasi}</td>

                                                    </tr> */}
                                                    <tr>
                                                        <td>Jumlah Donasi</td>
                                                        <td>:</td>
                                                        <td className="text-right">{donasi.data.total_amount}</td>

                                                    </tr>
                                                </Table>
                                            </div>
                                            <div className="col-md-2"></div>
                                        </div>


                                    </CardBody>
                                </Card>
                            }

                            {
                                show && !donasi.verifyStatus &&
                                <Card>
                                    <CardBody>
                                        <div className="row">

                                            <div className="col-md-3"></div>
                                            <div className="col-md-6 text-center">
                                                <h3 className="text-center">Bukti Setor Anda Tidak Terdaftar Pada Sistem, Hubungi Customer Care Untuk Info Lebih Lanjut</h3>
                                            </div>
                                            <div className="col-md-3"></div>
                                            <div className="col-md-3"></div>
                                            <div className="col-md-6 text-center">
                                                <span className="svg-icon svg-icon-danger svg-icon-10x ">
                                                    <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Close.svg')} />
                                                </span>
                                            </div>

                                        </div>


                                    </CardBody>
                                </Card>
                            }

                        </div>
                        <div className="" style={{
                            // backgroundColor: "#5926A5",
                            // backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-4.jpg")})`
                        }}>
                            <p className="text-lg-right text-center font-weight-lighter opacity-80" style={{ fontSize: "15px" }}>Lupa password atau mengalami kendala ?<br />
                                Hubungi Customer Care Dompet Dhuafa<br />
                                Call center: 0804-100-4000 / (021) 508 66860 WA center: +62811-1544-488<br /></p>
                        </div>
                        {/*end::Content body*/}

                        {/* begin::Mobile footer */}
                        <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center">
                            <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                                &copy; 2021 Dompet Dhuafa
                            </div>

                        </div>
                        {/* end::Mobile footer */}
                    </div>
                    {/*end::Content*/}
                </div>
                {/*end::Login*/}
            </div>
        </>

    );
};

export default VerifyCard;
