import axios from "axios";
import { DonorURL } from '../../../config/UrlEndPoint';


export const URL = `${DonorURL}`;


// items => filtered/sorted result for table data
export function changePassword(params) {
  return axios.put(`${URL}otp/changepass`, {
      password: params.password, 
      confirmationPassword: params.confirmationPassword,
      email: params.email,
    });
}

// items => filtered/sorted result for table data
export function verifyOtp(params) {
  return axios.post(`${URL}verifyOtp`, {email: params.email, otp: params.otp});
}
