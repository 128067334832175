import React from 'react';
export function TypeColumnFormatter(cellContent, row) {

    return (
        <ul className="" style={{
            marginLeft: "-25px"
        }}>
            {cellContent.map((item) => {
                return <li>{item.type}</li>
            })}
        </ul>
    );
}
