import * as requestFromServer from './donationCrud';
import { donationSlice, callTypes } from './donationSlice';
import { actions as auth } from '../../../modules/Auth/_redux/authRedux';
import { toastOption } from '../../../../_metronic/_helpers/ToastHelpers';
import { toast } from 'react-toastify';

const { actions } = donationSlice;

export const fetchDonations = (queryParams) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer.findDonation(queryParams)
        .then(response => {
            const { data: { total_items, items } } = response.data;
            dispatch(actions.donationsFetched({ total_items, items }));
        }).catch(error => {
            if (error.response.data.error === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout());
            }
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

// export const fetchDonatur = (id) => dispatch => {
//     if (!id) {
//         return dispatch(actions.donaturFetched({ donaturForEdit: undefined }));
//     }
//
//     dispatch(actions.startCall({ callType: callTypes.action }));
//     return requestFromServer.getDonaturById(id)
//         .then(response => {
//             const { data: donatur } = response.data; // alias data -> donatur
//             dispatch(actions.donaturFetched({ donaturForEdit: donatur }));
//             return donatur;
//         }).catch(error => {
//             if (error.response.data.error === 401) {
//                 toast.error(error.response.data.message, toastOption);
//                 dispatch(auth.logout())
//             }
//             error = error.response.data.message;
//             dispatch(actions.catchError({ error, callType: callTypes.action }));
//         });
// };

// export const updateDonatur = (donatur) => dispatch => {
//     dispatch(actions.startCall({ callType: callTypes.action }));
//     return requestFromServer.updateDonatur(donatur)
//         .then(response => {
//             dispatch(actions.donatursReload);
//             return response.data;
//         })
//         .catch(error => {
//             if (error.response.data.error === 401) {
//                 toast.error(error.response.data.message, toastOption);
//                 dispatch(auth.logout())
//             }
//             error = error.response.data.message;
//             dispatch(actions.catchError({ error, callType: callTypes.list }));
//         });
// };
