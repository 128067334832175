import React from 'react';
import axios from 'axios';
import { DonorURL } from '../../config/UrlEndPoint';


export const URL = `${DonorURL}`;

// items => filtered/sorted result
export async function findDonation() {
    // const dispatch = useDispatch();
    const res = await axios.get(`${URL}recap/dashboard-donation`);
    // if (res.response.data.error === 401) {
    //     toast.error(res.response.data.message, toastOption);
    //     dispatch(auth.logout());
    // }
    return res.data
}

// export async function downloadReport() {
//     // const res = await axios.get(`${URL}recap/download-report`)
//     // console.log(res);
//     // return res
// }