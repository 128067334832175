/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import {useLocation} from 'react-router';
import {NavLink} from 'react-router-dom';
import {checkIsActive} from '../../../../_helpers';
import * as donationCrud from "../../../../../app/pages/Dashboard/DashboardCrud"

// import { shallowEqual, useSelector } from 'react-redux';

export function HeaderMenu({layoutProps}) {
    const location = useLocation();
    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? 'menu-item-active' : '';
    };

    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-left header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
        {/*begin::Header Nav*/}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {/*begin::1 Level*/}
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/dashboard')}`}>
                <NavLink className="menu-link" to="/dashboard">
                    <span className="menu-text">Dashboard </span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow"/>)}
                </NavLink>
            </li>

            {/*<li className={`menu-item menu-item-rel ${getMenuItemActive('/user-profile')}`}>*/}
            {/*    <NavLink className="menu-link" to="/user-profile">*/}
            {/*        <span className="menu-text">Profile</span>*/}
            {/*        {layoutProps.rootArrowEnabled && (<i className="menu-arrow"/>)}*/}
            {/*    </NavLink>*/}
            {/*</li>*/}

            <li
                data-menu-toggle={layoutProps.menuDesktopToggle}
                aria-haspopup="true"
                className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/user-profile')}`}>
                <NavLink className="menu-link menu-toggle" to="/user-profile">
                    <span className="menu-text">Profile</span>
                    <i className="menu-arrow"></i>
                </NavLink>
                <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                    <ul className="menu-subnav">
                        {/* <li
                            className={`menu-item menu-item-submenu ${getMenuItemActive('/user-profile/profile-overview')}`}
                            data-menu-toggle="hover"
                            aria-haspopup="true"
                        >
                            <NavLink className="menu-link" to="/user-profile/profile-overview">
                                    <span className="menu-text">
                                    Profile Overview
                                    </span>
                            </NavLink>
                        </li> */}
                        <li
                            className={`menu-item menu-item-submenu ${getMenuItemActive('/user-profile/account-information')}`}
                            data-menu-toggle="hover"
                            aria-haspopup="true"
                        >
                            <NavLink className="menu-link" to="/user-profile/personal-information">
                                    <span className="menu-text">
                                    Personal Information
                                    </span>
                            </NavLink>
                        </li>
                        <li
                            className={`menu-item menu-item-submenu ${getMenuItemActive('/user-profile/change-password')}`}
                            data-menu-toggle="hover"
                            aria-haspopup="true"
                        >
                            <NavLink className="menu-link" to="/user-profile/change-password">
                                    <span className="menu-text">
                                    Change Password
                                    </span>
                            </NavLink>
                        </li>
                        <li
                            className={`menu-item menu-item-submenu ${getMenuItemActive('/user-profile/email-settings')}`}
                            data-menu-toggle="hover"
                            aria-haspopup="true"
                        >
                            <NavLink className="menu-link" to="/user-profile/email-settings">
                                    <span className="menu-text">
                                   Notification Setting
                                    </span>
                            </NavLink>
                        </li>
                        <li
                            className={`menu-item menu-item-submenu ${getMenuItemActive('/user-profile/login-session')}`}
                            data-menu-toggle="hover"
                            aria-haspopup="true"
                        >
                            <NavLink className="menu-link" to="/user-profile/login-session">
                                    <span className="menu-text">
                                    Login Session
                                    </span>
                            </NavLink>
                        </li>

                    </ul>
                </div>
            </li>

            <li className={`menu-item menu-item-rel ${getMenuItemActive('/donasi')}`}>
                <NavLink className="menu-link" to="/donasi">
                    <span className="menu-text">Donasi</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow"/>)}
                </NavLink>
            </li>

            <li className={`menu-item menu-item-rel ${getMenuItemActive('/download-report')}`}>
                <NavLink className="menu-link" to="/download-report">
                    <span className="menu-text">Download Report</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow"/>)}
                </NavLink>
            </li>


            {/*MY PAGE*/}
            {/*<li className={`menu-item menu-item-rel ${getMenuItemActive('/my-page')}`}>*/}
            {/*    <NavLink className="menu-link" to="/my-page">*/}
            {/*        <span className="menu-text">My Page</span>*/}
            {/*        {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}*/}
            {/*    </NavLink>*/}
            {/*</li>*/}
            {/*end::1 Level*/}

        </ul>
        {/*end::Header Nav*/}
    </div>;
}
