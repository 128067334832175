import axios from 'axios';
import { callTypes, exportListSlice } from './DownloadReportSlice';
import { DonorURL } from '../../../config/UrlEndPoint';
import { toastOption } from '../../../../_metronic/_helpers/ToastHelpers'
import {actions as auth} from "../../Auth/_redux/authRedux"
import { toast } from 'react-toastify'
export const URL = `${DonorURL}`;

const { actions } = exportListSlice


export const downloadReport = (queryParams, buttonClicked) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    dispatch(actions.startCall({ loadingProgress: true, buttonClicked:buttonClicked }));

    return axios.post(`${DonorURL}recap/download-report`, {year: queryParams}, {
        responseType: 'blob',
        onDownloadProgress: (ProgressEvent) => {
            let percentCompleted = Math.floor(ProgressEvent.loaded / ProgressEvent.total * 100)
            dispatch(actions.startCall({ loadingProgress: percentCompleted }));
            return percentCompleted
        }
    })
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Report ${queryParams}.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
            dispatch(actions.exportListReload());
        }).catch(error => {
            if (error.response.status === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout())
            }
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};