import axios from 'axios';
import { DonorURL } from '../../../config/UrlEndPoint';

export const URL = `${DonorURL}donasi`;

// items => filtered/sorted result
export function findDonation(queryParams) {
    return axios.post(`${URL}/find`, queryParams);
}

// //Get By ID
// export function getDonationById(donaturId) {
//     return axios.get(`${URL}/${donaturId}`);
// }

export function getLog() {
    return axios.get(`${URL}/log`);
}
