import React from "react";
import {
  CustomWidget1,
  CustomStatsWidget1,
  CustomListWidget1
} from "./widget"

export function DownloadReportPage() {
  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          {/* <CustomWidget1 className="card-stretch gutter-b" /> */}
          <CustomListWidget1 className="card-stretch gutter-b" />
        </div>
      </div>
    </>
  );
}
