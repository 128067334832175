/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link, Switch, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import "../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import { oAuthMe ,getOAuthApp } from "./_redux/OAuthAppCrud";

export function OAuthAppPage({ history, match }) {
  const params = match && match.params && match.params.id
  const token = match && match.params && match.params.token

  const [oAuthApp, setOAuthApp] = useState({})
  const [user, setUser] = useState({})

  useEffect(() => {
    oAuthMe(token).then(({data}) => setUser(data)).catch(err => {
      console.log(err)
      history.push(`/oauth/login/${params}`)
    })
    getOAuthApp(params).then(({data}) => setOAuthApp(data.data))
  }, [])

  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Aside*/}
          <div
            className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-4.jpg")})`,
            }}
          >
            {/*begin: Aside Container*/}
            <div className="d-flex flex-row-fluid flex-column justify-content-between">
              {/* start:: Aside header */}
              <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
                <img
                  alt="Logo"
                  className="max-h-70px"
                  src={toAbsoluteUrl("/media/logos/logo-letter-1.png")}
                />
              </Link>
              {/* end:: Aside header */}

              {/* start:: Aside content */}
              <div className="flex-column-fluid d-flex flex-column justify-content-center">
                <h3 className="font-size-h1 mb-5 text-white">
                  Portal Donatur
                </h3>
                {/* <p className="font-weight-lighter text-white opacity-80">
                  <i>Pelayanan Mandiri Dompetdhuafa</i>
                </p> */}
                <p className="font-weight-lighter text-white opacity-80">
                  <i>Portal Layanan Mandiri Donatur Dompet Dhuafa</i>
                </p>
              </div>
              {/* end:: Aside content */}

              {/* start:: Aside footer for desktop */}
              <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                <div className="opacity-70 font-weight-bold	text-white">
                  &copy; 2021 Dompet Dhuafa
                </div>

              </div>
              {/* end:: Aside footer for desktop */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">

            {/* begin::Content body */}
            <div className="d-flex flex-column-fluid flex-center">
            <div className="login-form login-signin" id="kt_login_signin_form">
            {/* begin::Head */}
            <div className="text-center mb-10 mb-lg-20">
                <h3 className="font-size-h1">
                    {/* <FormattedMessage id="AUTH.LOGIN.TITLE" /> */}
                    <h1>{oAuthApp.name}</h1>

                </h3>
                <p className="font-weight-bold mt-5">
                    Aplikasi ini ingin mengakses data anda. Jika setuju maka keseluruhan data anda akan dapat dibaca oleh aplikasi ini.
                     Apakah anda setuju?
                </p>
            </div>
            {/* end::Head */}

            {/*begin::Form*/}
                <div className="form-group d-flex flex-wrap justify-content-between align-items-center">

                <button
                        type="submit"
                        onClick={() => history.push(`/oauth/login/${params}`)}
                        className={`btn btn-outline-danger font-weight-bold px-9 py-4 my-3`}
                    >
                        <span>Tidak Setuju</span>
                    </button>

                    <div className="my-3 mr-2"></div>
                      <button
                          type="submit"
                          // disabled={formik.isSubmitting}
                          onClick={() => window.open(`${oAuthApp.url_callback}/${token}`, "_self")}
                          className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                      >
                          <span>Setuju</span>
                      </button>
                </div>
            {/*end::Form*/}
        </div>
            </div>
            <div className="" style={{
              // backgroundColor: "#5926A5",
              // backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-4.jpg")})`
            }}>
              <p className="text-lg-right text-center font-weight-lighter opacity-80 p-7">Mengalami kendala ?<br />
                Hubungi Customer Care Dompet Dhuafa<br />
                Call center: 0804-100-4000 / (021) 508 66860 WA center: +62811-1544-488<br /></p>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                &copy; 2021 Dompet Dhuafa
              </div>

            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
