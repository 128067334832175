import React from "react";
import {
  CustomWidget1,
  CustomStatsWidget1
} from "./widget"

export function CustomDashboard() {
  return (
    <>

      <div className="row">
        {/* <div className="col-lg-12 col-xxl-4">
          <MixedWidget1 className="card-stretch gutter-b" />
        </div> */}


        <div className="col-lg-12">
          {/*Total Donasi*/}
          <CustomStatsWidget1
            className="card-stretch gutter-b"
            symbolShape="circle"
            baseColor="success"
          />
          {/*<StatsWidget12 className="card-stretch card-stretch-half gutter-b" />*/}
        </div>

        {/*<div className="col-lg-6 col-xxl-4 order-1 order-xxl-1">*/}
        {/*  <ListsWidget1 className="card-stretch gutter-b" />*/}
        {/*</div>*/}
        {/*<div className="col-xxl-8 order-2 order-xxl-1">*/}
        {/*  <AdvanceTablesWidget2 className="card-stretch gutter-b" />*/}
        {/*</div>*/}
        {/*<div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">*/}
        {/*  <ListsWidget3 className="card-stretch gutter-b" />*/}
        {/*</div>*/}
        {/*<div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">*/}
        {/*  <ListsWidget4 className="card-stretch gutter-b" />*/}
        {/*</div>*/}
        {/*<div className="col-lg-12 col-xxl-4 order-1 order-xxl-2">*/}
        {/*  <ListsWidget8 className="card-stretch gutter-b" />*/}
        {/*</div>*/}
      </div>
      <div className="row">
        <div className="col-lg-12">
          <CustomWidget1 className="card-stretch gutter-b" />
        </div>
      </div>

      {/*TimeLine*/}
      {/* <div className="row">
        <div className="col-lg-12 col-xxl-12">
          <ListsWidget9 className="card-stretch gutter-b" />
        </div>
      </div> */}

      {/*<div className="row">*/}
      {/*  <div className="col-lg-4">*/}
      {/*    <MixedWidget14 className="card-stretch gutter-b" />*/}
      {/*  </div>*/}
      {/*  <div className="col-lg-8">*/}
      {/*    <AdvanceTablesWidget4 className="card-stretch gutter-b" />*/}
      {/*  </div>*/}
      {/*</div>*/}
    </>
  );
}
